import type { AgGridEvent } from 'ag-grid-community';
import type { GridApi } from 'ag-grid-enterprise';
import { compact, xor } from 'lodash';
import { useEffect } from 'react';
import { useDynamicCallback } from '../../../hooks/useDynamicCallback';
import { useNamedState } from '../../../hooks/useNamedState';
import { safeGridApi } from '../../BlotterTable/utils';

/**
 * A hook to trigger a callback every time the rendered rows in an AgGrid changes.
 *
 * @param gridApi Api object for the grid this hook belongs to
 * @param callback The function to be called when the rendered rows changes
 */
export function useRenderedRows<TData = unknown>(
  gridApi: GridApi | null | undefined,
  callback: (event: AgGridEvent<TData>, rowIds: string[]) => void
) {
  const [renderedRowIds, setRenderedRowIds] = useNamedState<string[]>([], 'renderedRowIds');

  const filteredCallback = useDynamicCallback((event: AgGridEvent<TData>) => {
    const isDestroyed = !event.api || event.api.isDestroyed();
    if (isDestroyed) {
      return;
    }
    const latestRenderedRowIds = compact(
      event.api
        .getRenderedNodes()
        .map(({ id }) => id)
        .sort()
    );
    if (xor(renderedRowIds, latestRenderedRowIds).length) {
      setRenderedRowIds(latestRenderedRowIds);
      callback(event, latestRenderedRowIds);
    }
  });
  useEffect(() => {
    safeGridApi(gridApi)?.addEventListener('firstDataRendered', filteredCallback);
    safeGridApi(gridApi)?.addEventListener('modelUpdated', filteredCallback);
    safeGridApi(gridApi)?.addEventListener('bodyScrollEnd', filteredCallback);
    return () => {
      safeGridApi(gridApi)?.removeEventListener('firstDataRendered', filteredCallback);
      safeGridApi(gridApi)?.removeEventListener('modelUpdated', filteredCallback);
      safeGridApi(gridApi)?.removeEventListener('bodyScrollEnd', filteredCallback);
    };
  }, [gridApi, filteredCallback]);
}
